import Wrapper from "Components/Wrapper";
import { DashboardFunc } from "./types";

const DataAnalytics: DashboardFunc = () => {
  return (
    <Wrapper>
      <iframe
        src="https://lookerstudio.google.com/embed/reporting/89f9a825-0352-494f-95cc-09a73448b7b9/page/kIV1C"
        width="100%"
        style={{ height: "100vh" }}
        title="Data analytics"
      />
    </Wrapper>
  );
};
export default DataAnalytics;
